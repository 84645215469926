import { MenuEntry } from '@mozartfinance/uikit'

const config: MenuEntry[] = [
  {
    label: 'Home',
    icon: 'HomeIcon',
    href: '/',
  },
  {
    label: 'Exchange',
    icon: 'TradeIcon',
    href: '/swap',
  },
  {
    label: 'Liquidity',
    icon: 'PoolIcon',
    href: '/pool',
  },
  {
    label: 'Chart',
    icon: 'InfoIcon',
    href: '/chart',
  },
  /*
  {
    label: 'Launchpad',
    icon: 'MoreIcon',
    href: 'launchpads',
  },
  {
    label: 'Airdrop',
    icon: 'MoreIcon',
    href: '/airdrops',
  },
  {
    label: 'SWAPO Token',
    icon: 'MoreIcon',
    href: 'https://bscscan.com/token/0xB5b28fA16d568206C8091D136543f84345101263',
  },
  {
    label: 'Pools',
    icon: 'PoolIcon',
    href: 'https://mozartfinance.io/pools',
  },
  {
    label: 'Audit by IB',
    icon: 'TicketIcon',
    href: 'https://github.com/ImmuneBytes/Smart-Contract-Audit-Reports/blob/main/Mozart%20Finance/Mozart%20Finance%20(PIANO)%20-%20Final%20Audit%20Report.pdf',
  }, */
]

export default config
